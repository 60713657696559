import type { LocationQuery, RouteLocation } from 'vue-router'

/*
 * STANDARD EVENT LIBRARY TYPES AND INTERFACES
 *
 * @deprecated This is just waiting to die.
 */
type StandardLibraryEventType = Record<
  string,
  (
    this: ComponentPublicInstance,
    additionalContent: Record<string, any>,
  ) => void
>
/**
 * @deprecated This is just waiting to die.
 */
type AvailableStandardEventKeys = 'ga'
/**
 * @deprecated This is just waiting to die.
 */
type AvailableEventKeys = AvailableStandardEventKeys
/**
 * @deprecated This is just waiting to die.
 */
type StandardEventTranslationLibrary = Record<
  AvailableStandardEventKeys,
  StandardLibraryEventType
>

/*
 * EVENT TYPES AND INTERFACES
 *
 * @deprecated This is just waiting to die.
 */
interface EventMinArgs {
  category: string
  action: string
  label?: string
}

/**
 * @deprecated This is just waiting to die.
 */
type EventAcceptedData = Record<string, any> & EventMinArgs
/**
 * @deprecated This is just waiting to die.
 */
interface EventRequiredData extends EventMinArgs {
  previousUrl?: string
  hash?: string
}
/**
 * @deprecated This is just waiting to die.
 */
type EventData = Record<string, any> & EventRequiredData
/**
 * @deprecated This is just waiting to die.
 */
interface InternEventDetails {
  details: Record<string, any>
}
// type InternDetails = InternEventDetails & EventData

/**
 * @deprecated This is just waiting to die.
 */
function translationLib(): StandardEventTranslationLibrary {
  return {
    ga: {
      purchase(additionalContent: Record<string, any>) {
        // const userStore = useUserStore()
        // const id = userStore.id + '__' + new Date().getTime()
        // this.$ga.ecommerce.addTransaction({
        //   id: id,
        //   affiliation: additionalContent.label,
        //   revenue: additionalContent.value,
        //   shipping: '0',
        //   tax: '0',
        //   //@ts-ignore Trust
        //   currency: 'EUR',
        // })
        // this.$ga.ecommerce.addItem({
        //   id,
        //   name:
        //     additionalContent.pack_name || additionalContent.content_ids[0],
        //   price: additionalContent.value,
        //   quantity: 1,
        //   //@ts-ignore Trust
        //   currency: 'EUR',
        // })
        // this.$ga.ecommerce.send()
      },
      completeRegistration() {
        // this.$ga.event({
        //   eventCategory: 'Signup',
        //   eventAction: 'Signup Artist Succeeded',
        //   eventValue: userStore.id,
        // })
      },
    },
  }
}

/**
 * @deprecated This is just waiting to die.
 */
function getLaunchArray(): AvailableEventKeys[] {
  const ret: AvailableEventKeys[] = ['ga']

  return ret
}

/**
 * @param actionName
 * @param _label
 * @param additionalContent
 * @deprecated This is just waiting to die.
 */
export function trackStandardEvent(
  actionName: string,
  _label: string,
  additionalContent: string | Record<string, any>,
) {
  getLaunchArray().forEach((eventType: AvailableEventKeys) => {
    const translationLibData = translationLib()
    if (
      translationLibData[eventType] &&
      typeof translationLibData[eventType][actionName] === 'function'
    )
      // @ts-expect-error trust
      translationLibData[eventType][actionName](additionalContent)
  })
}

function buildQuery(data: EventData, routeQuery: LocationQuery): EventData {
  for (const key in routeQuery) data[`query_${key}`] = routeQuery[key]

  return data
}

/**
 * Our legacy event tracking method.
 *
 * @param data
 * @param root0
 * @param root0.path
 * @param root0.query
 * @param root0.hash
 * @deprecated This is just waiting to die.
 */
export function trackEvent(
  data: EventAcceptedData,
  { path, query, hash }: RouteLocation,
) {
  if (typeof data === 'object') {
    if (typeof data.label === 'undefined') data.label = path

    data = buildQuery(data, query)

    data.previousUrl = window?.document?.referrer || undefined
    data.hash = hash
  }
}
